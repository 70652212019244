<template>
  <div v-if="show" class="modal-background">
      <div class="modal-body">
          <slot></slot>
      <button @click="closeModal">
          X
      </button>
      </div>
  </div>
</template>

<script>
export default {
    props: ['show'],
    methods: {
        closeModal(){
            this.$emit('closeModal', false)
        }
    }

}
</script>

<style scoped>
    .modal-background {
        position: absolute;
        top: 0;
        left: 0;
        height: fit-content;
        width: 100vw;
        background: rgba(0,0,0, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 40px;
    }

    .modal-body {
        border-radius: 16px;
        background: #fff;
        position: relative;
    }

    button {
        position: absolute;
        background: #fff;
        top: 0;
        right: -45px;
        background: #fff;
        border: none;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        cursor: pointer;
    }
</style>