<template>
  <div>

    <div class="d-flex justify-between align-items-center">
      <h3>{{ title }}</h3>
      <Links :to="to" label="Ver todos"></Links>
    </div>
    <div>
      <VueSlickCarousel v-bind="settings">
        <div class="card" v-for="content of dataContent" :key="content._id">
          <router-link :to="'/detalhe/'+content._id+'/?tipo='+title">
            <img
              :src="content.capas[0]"
              :alt="content.nome"
            />
          </router-link>
        </div>
        
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import Links from "../common/Links/Links.vue";
import VueSlickCarousel from "vue-slick-carousel";

export default {
  components: {
    Links,
    VueSlickCarousel,
  },
  props: ["title", "to", "dataContent"],
  data() {
    return {
      settings: {
        dots: false,
        arrows: false,
        edgeFriction: 0.35,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        centerPadding: "15px",
        responsive: [
          {
            breakpoint: 2000,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
          {
            breakpoint: 1500,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
        ],
        
      },
    };
  },
};
</script>

<style scoped>
h3 {
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.4px;
  color: #009987;
}

.card {
  width: 100%;
  height: 231px;
  margin-right: 15px;
}

.card img {
  max-width: 100%;
  max-height: 100%;
}

.slick-slider {
  margin-top: 19px !important;
  margin-bottom: 30px !important;
}

@media screen and (min-width: 700px){
  .card {
    height: 370px;
    width: calc(25%-16px)
  }

  h3 {
    font-size: 1.6rem;
  }
}
</style>